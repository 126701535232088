import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Terms() {
  return (
    <div className="bg-purple-50 flex flex-col items-center">
      <Header />
      <div className="max-w-4xl mx-auto rounded-lg shadow-lg p-8 m-5">
        <h1 className="text-2xl font-bold mb-4">Terms and Conditions</h1>
        <p className="mb-4">
          Welcome to <strong>FastControl</strong>!
        </p>
        <p className="mb-4">
          These terms and conditions outline the rules and regulations for the
          use of <strong>FastControl's</strong> website, located at{" "}
          <a
            href="https://fastcontrol.io/"
            className="text-blue-500 hover:underline"
          >
            https://fastcontrol.io/
          </a>
          .
        </p>
        <p className="mb-4">
          By accessing this website, we assume you accept these terms and
          conditions. Do not continue to use <strong>FastControl</strong> if you
          do not agree to take all of the terms and conditions stated on this
          page.
        </p>
        <h2 className="text-xl font-semibold mb-3">Cookies</h2>
        <p className="mb-4">
          We employ the use of cookies. By accessing{" "}
          <strong>FastControl</strong>, you agree to use cookies in accordance
          with <strong>FastControl's</strong> Privacy Policy. Most interactive
          websites use cookies to let us retrieve the user's details for each
          visit. Cookies are used by our website to enable the functionality of
          certain areas to make it easier for people visiting our website.
        </p>
        <h2 className="text-xl font-semibold mb-3">License</h2>
        <p className="mb-4">
          Unless otherwise stated, <strong>FastControl</strong> and/or its
          licensors own the intellectual property rights for all material on{" "}
          <strong>FastControl</strong>. All intellectual property rights are
          reserved. You may access this from <strong>FastControl</strong> for
          your own personal use subjected to restrictions set in these terms and
          conditions.
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            You must not republish material from <strong>FastControl</strong>.
          </li>
          <li>
            You must not sell, rent, or sub-license material from{" "}
            <strong>FastControl</strong>.
          </li>
          <li>
            You must not reproduce, duplicate or copy material from{" "}
            <strong>FastControl</strong>.
          </li>
          <li>
            You must not redistribute content from <strong>FastControl</strong>.
          </li>
        </ul>
        <h2 className="text-xl font-semibold mb-3">
          Hyperlinking to our Content
        </h2>
        <p className="mb-4">
          The following organizations may link to our Website without prior
          written approval: Government agencies, Search engines, News
          organizations, and System-wide Accredited Businesses except soliciting
          non-profit organizations.
        </p>
        <p className="mb-4">
          These organizations may link to our home page, to publications, or to
          other Website information so long as the link: (a) is not in any way
          deceptive; (b) does not falsely imply sponsorship or endorsement; and
          (c) fits within the context of the linking party's site.
        </p>
        <h2 className="text-xl font-semibold mb-3">iFrames</h2>
        <p className="mb-4">
          Without prior approval and written permission, you may not create
          frames around our Webpages that alter in any way the visual
          presentation or appearance of our Website.
        </p>
        <h2 className="text-xl font-semibold mb-3">Content Liability</h2>
        <p className="mb-4">
          We shall not be held responsible for any content that appears on your
          Website. You agree to protect and defend us against all claims that
          arise from your Website.
        </p>
        <h2 className="text-xl font-semibold mb-3">Disclaimer</h2>
        <p className="mb-4">
          To the maximum extent permitted by applicable law, we exclude all
          representations, warranties, and conditions relating to our website
          and the use of this website. As long as the website and the
          information and services on the website are provided free of charge,
          we will not be liable for any loss or damage of any nature.
        </p>
      </div>
      <Footer />
    </div>
  );
}
