import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Header() {
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const token = localStorage.getItem("token");

  // Logout function to clear the token and redirect to login
  const handleLogout = () => {
    localStorage.removeItem("token"); // Clear the token
    localStorage.removeItem("subscription_status"); // Clear subscription status
    window.location.href = "/login"; // Redirect to login
  };

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const token = localStorage.getItem("token"); // Get token from localStorage
        const email = localStorage.getItem("email"); // Get email from localStorage
        const apiUrl = process.env.REACT_APP_API_URL;

        // Make the GET request with email as a query parameter and token in headers
        const response = await axios.get(
          `${apiUrl}/subscription-status/?email=${email}`,
          {}
        );

        // Set the subscription status in the component's state
        setSubscriptionStatus(response.data.subscription_status);
      } catch (error) {
        console.error("Error fetching subscription status:", error);
      }
    };

    fetchSubscriptionStatus();
  }, []);

  return (
    <div className="w-full flex flex-col items-center">
      <div className="text-gray-700 items-center justify-between py-4 px-8 flex w-full sm:w-9/12">
        <div className="items-center flex flex-col sm:flex-row gap-6 sm:gap-12">
          <div className="items-center flex text-xl font-bold">
            <a
              className="items-center flex ml-2 sm:ml-0 gap-2 sm:gap-4"
              href="/"
            >
              <img
                className="cursor-pointer w-8 h-8 sm:w-12 sm:h-12"
                src={require("../images/duino.png")}
                alt="FastControl"
              />
              <span className="cursor-pointer hidden sm:block">
                FastControl
              </span>
            </a>
          </div>
          <div className="font-semibold hidden sm:flex text-gray-500">
            <ul className="flex list-none gap-4 sm:gap-8">
              <li className="list-item">
                <button
                  onClick={() => {
                    window.location.href = "/#features";
                  }}
                  className="cursor-pointer text-center inline-block w-auto h-auto rounded-2xl p-2"
                >
                  Features
                </button>
              </li>
              <li className="list-item">
                <button
                  onClick={() => {
                    window.location.href = "/#pricing";
                  }}
                  className="cursor-pointer text-center inline-block w-auto h-auto rounded-2xl p-2"
                >
                  Pricing
                </button>
              </li>
              <li className="list-item">
                <button
                  onClick={() => {
                    window.location.href = "/#contact";
                  }}
                  className="cursor-pointer text-center inline-block w-auto h-auto rounded-2xl p-2"
                >
                  Contact
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div className="items-center flex gap-2 sm:gap-4 font-semibold">
          {/* IDE Button */}
          {/* <button
            onClick={() => {
              if (token && subscriptionStatus === "active") {
                window.location.href = "https://demo1.duino.io/";
              }
            }}
            disabled={!token || subscriptionStatus !== "active"} // Disable button if no token or subscription is not active
            className={`transition-transform transform hover:scale-105 duration-300 text-sm py-2 px-4 sm:py-3 sm:px-6 text-center flex rounded-xl ${
              token && subscriptionStatus === "active"
                ? "bg-green-500 text-white cursor-pointer" // Enabled state
                : "bg-gray-300 text-gray-500 cursor-not-allowed" // Disabled state
            }`}
            title={
              !token
                ? "You need to log in first"
                : subscriptionStatus !== "active"
                ? "You need an active subscription"
                : ""
            } // Show appropriate tooltip if not logged in or no active subscription
          >
            {token && subscriptionStatus === "active"
              ? "IDE"
              : "IDE (Disabled)"}
          </button> */}

          <button
            onClick={() => {
              window.location.href = "/signup";
            }}
            className="transition-transform transform hover:scale-105 duration-300 bg-purple-400 text-white cursor-pointer text-sm py-2 px-4 sm:py-3 sm:px-6 text-center flex rounded-xl"
          >
            Signup
          </button>
          <a
            href="/login"
            className="py-1 px-3 cursor-pointer rounded-xl sm:pl-6 sm:pr-6 sm:pt-3 sm:pb-3"
          >
            Login
          </a>
        </div>
      </div>
    </div>
  );
}
