import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Signup() {
  // State to manage form inputs
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Function to handle form submission
  const handleSignup = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    // Simple validation for password confirmation
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match!");
      return;
    }

    try {
      // Fetch the API URL from environment variables
      const apiUrl = process.env.REACT_APP_API_URL;

      // Send signup request to FastAPI backend
      const response = await axios.post(`${apiUrl}/signup/`, {
        email,
        password,
      });

      localStorage.setItem("email", email); // Save email to localStorage
      localStorage.setItem("password", password); // Save password to sessionStorage

      // On success, show a success message
      setSuccessMessage(
        "Account created successfully! Please activate your account."
      );
      console.log("Signup successful:", response.data);
      window.location.href = `/activation-email?email=${email}`;
    } catch (error) {
      // Handle errors from the backend, such as existing email
      setErrorMessage(error.response?.data?.detail || "Signup failed.");
      console.error("Signup error:", error);
    }
  };

  return (
    <div className="bg-purple-50 flex flex-col items-center">
      <Header />
      <div className="text-gray-700 p-6 sm:p-8 sm:w-8/12">
        <h1 className="text-gray-900 text-2xl font-bold">Create an account</h1>

        {/* Display error or success messages */}
        {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        {successMessage && <p className="text-green-500">{successMessage}</p>}

        <form className="mt-4 sm:mt-6" onSubmit={handleSignup}>
          <div>
            <label className="flex items-center py-2 text-sm">
              <span>Your email</span>
            </label>
            <input
              className="w-full px-4 py-2 border-2 border-gray-300 rounded-lg"
              placeholder="name@company.com"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mt-4 sm:mt-6">
            <label className="flex items-center py-2 text-sm">
              <span>Password</span>
            </label>
            <input
              className="w-full px-4 py-2 border-2 border-gray-300 rounded-lg"
              placeholder="••••••••"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="mt-4 sm:mt-6">
            <label className="flex items-center py-2 text-sm">
              <span>Confirm password</span>
            </label>
            <input
              className="w-full px-4 py-2 border-2 border-gray-300 rounded-lg"
              placeholder="••••••••"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex items-center mt-4 sm:mt-6">
            <input
              className="w-6 h-6 border-2 border-emerald-400 rounded-lg cursor-pointer"
              type="checkbox"
              required
            />
            <label className="ml-3 text-sm cursor-pointer flex items-center">
              I accept the
              <a
                className="ml-2 font-medium text-cyan-600"
                href="/terms-of-use"
              >
                Terms and Conditions
              </a>
            </label>
          </div>
          <button
            type="submit"
            className="bg-purple-400 text-white font-medium py-3 px-5 rounded-xl mt-4 sm:mt-6"
          >
            Create An Account
          </button>
          <p className="text-gray-500 text-sm mt-4 sm:mt-6">
            Already have an account?{" "}
            <a className="text-cyan-600 font-medium" href="/login">
              Login here
            </a>
          </p>
        </form>
      </div>
      <Footer />
    </div>
  );
}
