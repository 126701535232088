import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  // Extract token and email from URL parameters if present
  const token = queryParams.get('token');
  const resetEmail = queryParams.get('email');

  useEffect(() => {
    if (resetEmail) setEmail(resetEmail);
  }, [resetEmail]);

  // Handle forgot password request
  const handleForgotPassword = async (event) => {
    event.preventDefault();
    setErrorMessage('');

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${apiUrl}/forgot-password`, { email });

      if (response.status === 200) {
        setSuccessMessage('Check your email for the password reset link.');
        setTimeout(() => {
          window.location.href = '/login';
        }, 3000);
      }
    } catch (error) {
      setErrorMessage('Failed to send reset link. Please try again.');
      console.error('Forgot Password error:', error);
    }
  };

  // Handle password reset form submission
  const handleResetPassword = async (event) => {
    event.preventDefault();
    setErrorMessage('');

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match!');
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${apiUrl}/reset-password`, {
        token,
        email,
        new_password: password,
      });

      if (response.status === 200) {
        setSuccessMessage('Password reset successfully. Redirecting to login...');
        setTimeout(() => {
          window.location.href = '/login';
        }, 3000);
      }
    } catch (error) {
      setErrorMessage('Failed to reset password. Please try again.');
      console.error('Reset Password error:', error);
    }
  };

  return (
    <div className="flex flex-col items-center bg-purple-50 min-h-screen">
      <Header />
      <div className="text-gray-700 p-8 sm:w-8/12 flex flex-grow flex-col justify-start">
        <h1 className="text-gray-900 text-2xl font-bold">
          {token ? 'Reset Password' : 'Forgot Password'}
        </h1>

        {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        {successMessage && <p className="text-green-500">{successMessage}</p>}

        {token ? (
          <form className="mt-4" onSubmit={handleResetPassword}>
            <div>
              <label className="flex items-center py-2 text-sm">
                <span>New Password</span>
              </label>
              <input
                className="w-full px-4 py-2 border-2 border-gray-300 rounded-lg"
                placeholder="Enter new password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <div>
              <label className="flex items-center py-2 text-sm">
                <span>Confirm Password</span>
              </label>
              <input
                className="w-full px-4 py-2 border-2 border-gray-300 rounded-lg"
                placeholder="Confirm new password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>

            <button
              type="submit"
              className="bg-purple-400 text-white font-medium py-3 px-5 rounded-xl mt-4"
            >
              Reset Password
            </button>
          </form>
        ) : (
          <form className="mt-4" onSubmit={handleForgotPassword}>
            <div>
              <label className="flex items-center py-2 text-sm">
                <span>Your email</span>
              </label>
              <input
                className="w-full px-4 py-2 border-2 border-gray-300 rounded-lg"
                placeholder="name@company.com"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="bg-purple-400 text-white font-medium py-3 px-5 rounded-xl mt-4"
            >
              Send Reset Link
            </button>
          </form>
        )}
      </div>
      <Footer />
    </div>
  );
}
