import React, { useEffect, useState } from "react";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(
  "pk_test_51PzWX5Dx7gDtpk7T6Y4efyiNwVH4HVZyQrfw9FzxTyfxZdMclo72O2tEY3vx5SOLZFNF2m4Ivd7yufPrPFBrX4uP00KS8w3Urz"
);

export default function EmailActivation() {
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get("email");
  const token = queryParams.get("token");

  // Handle the Stripe Checkout after activation
  const handleCheckout = async (priceId) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;

      // Call API to create a checkout session for the user
      const session = await axios.post(`${apiUrl}/create-checkout-session`, {
        priceId,
        email,
      });

      // Load Stripe and redirect to checkout
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: session.data.id });
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  useEffect(() => {
    const activateAccount = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;

        // Activate the account
        const activationResponse = await axios.get(`${apiUrl}/activate/`, {
          params: { email, token },
        });

        setMessage("🎉 Your account has been activated! Logging you in...");

        // After activation, perform login
        const loginResponse = await axios.post(`${apiUrl}/login/`, {
          email,
          password: localStorage.getItem("password"), // Get the password from sessionStorage
        });

        // Save token and email for authenticated actions
        localStorage.setItem("token", loginResponse.data.access_token);
        localStorage.setItem("email", email);

        // Automatically redirect to the checkout session for the selected plan
        await handleCheckout("price_1PzmkpDx7gDtpk7TBCucmdH1"); // Adjust priceId as needed
      } catch (error) {
        setMessage(
          "❌ Account activation failed. Please try again or reload the page."
        );
        console.error("Activation or login failed:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (email && token) {
      activateAccount();
    }
  }, [email, token]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 mb-4"></div>
          <p className="text-xl text-gray-700">
            Please check your email to activate your account.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-md w-full">
        <h1 className="text-2xl font-semibold text-center text-blue-600 mb-4">
          Account Activation
        </h1>
        <p className="text-center text-gray-600">{message}</p>
      </div>
    </div>
  );
}
