import React, { useEffect } from "react";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(
  "pk_test_51PzWX5Dx7gDtpk7T6Y4efyiNwVH4HVZyQrfw9FzxTyfxZdMclo72O2tEY3vx5SOLZFNF2m4Ivd7yufPrPFBrX4uP00KS8w3Urz"
);

export default function Plan() {
  const handleCheckout = async (priceId) => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email"); // Get email from localStorage

    // If no token, redirect to the login page
    if (!token) {
      window.location.href = "/login";
      return;
    }

    try {
      // Fetch API URL from environment variables
      const apiUrl = process.env.REACT_APP_API_URL;

      // Make the POST request to the FastAPI server
      const session = await axios.post(`${apiUrl}/create-checkout-session`, {
        priceId,
        email, // Pass the email in the request
      });

      // Wait for Stripe to be loaded (stripePromise is already defined elsewhere)
      const stripe = await stripePromise;

      // Redirect to the Stripe Checkout page using the session ID
      await stripe.redirectToCheckout({ sessionId: session.data.id });
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  return (
    <div id="pricing" className="w-full flex justify-center">
      <div
        className="text-gray-700 w-10/12 p-6 lg:pt-16 lg:pb-16 lg:pl-6 lg:pr-6 flex justify-center"
        id="div-1"
      >
        <div className="flex-wrap grid grid-cols-1 sm:grid-cols-2 gap-16 max-w-[800px]">
          {/* Pro Plan */}
          <div className="bg-white flex-col justify-between text-center flex max-w-lg mt-8 mx-auto mb-0 border-2 border-gray-200 rounded-lg p-6 lg:p-8 lg:mt-0">
            <h3 className="items-center text-2xl font-bold justify-between flex">
              Pro
            </h3>
            <div className="items-baseline text-[2.13rem] leading-9 font-extrabold flex">
              $5.99/month
            </div>
            <div className="bg-neutral-300 w-full h-0 my-8" />
            <ul className="list-none mb-8">
              <li className="flex items-center gap-2">
                <img
                  className="w-7 h-auto"
                  src="https://aiexcelbot.com/check-default.svg"
                  alt="check"
                />
                <span className="text-zinc-700 text-sm">
                  Full access to Arduino IDE
                </span>
              </li>
              <li className="flex items-center gap-2 mt-4">
                <img
                  className="w-7 h-auto"
                  src="https://aiexcelbot.com/check-default.svg"
                  alt="check"
                />
                <span className="text-zinc-700 text-sm text-left">
                  Access to Arduino board libraries and updates
                </span>
              </li>
              <li className="flex items-center gap-2 mt-4">
                <img
                  className="w-7 h-auto"
                  src="https://aiexcelbot.com/check-default.svg"
                  alt="check"
                />
                <span className="text-zinc-700 text-sm text-left">
                  Unlimited code compilation support
                </span>
              </li>
              {/* <li className="flex items-center gap-2 mt-4">
                <img
                  className="w-7 h-auto"
                  src="https://aiexcelbot.com/check-default.svg"
                  alt="check"
                />
                <span className="text-zinc-700 text-sm">
                  Unlimited code debugging assistance
                </span>
              </li> */}
            </ul>
            <div className="w-full text-zinc-700 font-bold">
              <button
                onClick={() => handleCheckout("price_1PzmkpDx7gDtpk7TBCucmdH1")}
                className="cursor-pointer w-full h-16 border-2 border-zinc-700 rounded-lg"
              >
                Get started
              </button>
            </div>
          </div>

          {/* Pro Annual Plan */}
          <div className="bg-purple-100 rounded-bl-lg rounded-br-lg flex-col justify-between relative text-center flex max-w-lg mt-8 mx-auto mb-0 border-2 border-purple-600 p-6 lg:p-8 lg:mt-0">
            <span className="text-white bg-purple-600 items-center rounded-tl-2xl rounded-tr-2xl text-xs font-semibold justify-center left-0 absolute top-[-2.25rem] w-full flex h-9">
              Best Value
            </span>
            <h3 className="items-center text-2xl font-bold justify-between flex">
              Pro Annual
              <div className="text-white bg-purple-600 items-center text-xs justify-center px-2 flex h-6 rounded-full">
                Save 30%
              </div>
            </h3>
            <div className="items-baseline text-[2.13rem] leading-9 font-extrabold flex">
              $49.99/year
            </div>
            <div className="bg-purple-600 w-full h-0 my-4" />
            <ul className="list-none mb-8">
              <li className="flex items-center gap-2">
                <img
                  className="w-7 h-auto"
                  src="https://aiexcelbot.com/check-default.svg"
                  alt="check"
                />
                <span className="text-zinc-700 text-sm">
                  Full access to Arduino IDE
                </span>
              </li>
              <li className="flex items-center gap-2 mt-4">
                <img
                  className="w-7 h-auto"
                  src="https://aiexcelbot.com/check-default.svg"
                  alt="check"
                />
                <span className="text-zinc-700 text-sm text-left">
                  Access to Arduino board libraries and updates
                </span>
              </li>
              <li className="flex items-center gap-2 mt-4">
                <img
                  className="w-7 h-auto"
                  src="https://aiexcelbot.com/check-default.svg"
                  alt="check"
                />
                <span className="text-zinc-700 text-sm text-left">
                  Unlimited code compilation support
                </span>
              </li>
            </ul>
            <div className="w-full text-zinc-700 font-bold">
              <button
                onClick={() => handleCheckout("price_1PzmmSDx7gDtpk7T3SY3O734")}
                className="bg-purple-400 text-white cursor-pointer w-full h-16 border-2 border-purple-400 rounded-lg"
              >
                Get started
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
