import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
  'pk_test_51PzWX5Dx7gDtpk7T6Y4efyiNwVH4HVZyQrfw9FzxTyfxZdMclo72O2tEY3vx5SOLZFNF2m4Ivd7yufPrPFBrX4uP00KS8w3Urz'
);

export default function Login() {
  // State to handle form inputs
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const apiUrl = process.env.REACT_APP_API_URL; // Send login request to FastAPI backend

      const response = await axios.post(`${apiUrl}/login/`, {
        email,
        password,
      }); // Extract access token and subscription status from the response

      const { access_token, subscription_status } = response.data; // Store the token in localStorage for future authentication

      localStorage.setItem('token', access_token);
      localStorage.setItem('email', email); // Check subscription status and redirect accordingly

      if (subscription_status === 'active') {
        window.location.href = 'https://fastcontrol.io/demo/';
      } else {
        // Redirect to Stripe checkout page if subscription is not active
        const stripe = await stripePromise;
        const session = await axios.post(`${apiUrl}/create-checkout-session`, {
          priceId: 'price_1PzmkpDx7gDtpk7TBCucmdH1', // Replace with the correct price ID
          email,
        });
        await stripe.redirectToCheckout({ sessionId: session.data.id });
      }
    } catch (error) {
      setErrorMessage('Invalid email or password. Please try again.');
      console.error('Login error:', error);
    }
  };

  // useEffect(() => {
  //   const token = localStorage.getItem("token");

  //   if (token) {
  //     window.location.href = "/";
  //   }
  // }, []);

  return (
    <div className='flex flex-col items-center bg-purple-50'>
      <Header />
      <div className='text-gray-700 p-8 sm:w-8/12'>
        <h1 className='text-gray-900 text-2xl font-bold'>Login</h1>

        {errorMessage && <p className='text-red-500'>{errorMessage}</p>}

        <form className='mt-4' onSubmit={handleLogin}>
          <div>
            <label className='flex items-center py-2 text-sm'>
              <span>Your email</span>
            </label>
            <input
              className='w-full px-4 py-2 border-2 border-gray-300 rounded-lg'
              placeholder='name@company.com'
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='mt-4'>
            <label className='flex items-center py-2 text-sm'>
              <span>Password</span>
            </label>
            <input
              className='w-full px-4 py-2 border-2 border-gray-300 rounded-lg'
              placeholder='••••••••'
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <a className='block mt-2 text-cyan-600 text-sm font-medium' href='forgot-password'>
            Forgot password?
          </a>
          <button
            type='submit'
            className='bg-purple-400 text-white font-medium py-3 px-5 rounded-xl mt-4'
          >
            Login
          </button>
          <p className='text-gray-500 text-sm font-light mt-4'>
            New to FastControl?{' '}
            <a className='text-cyan-600 font-medium' href='/signup'>
              Register here
            </a>
          </p>
        </form>
      </div>
      <Footer />
    </div>
  );
}
