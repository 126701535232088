import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Plan from "../components/Plan";
import Contact from "../components/Contact";

function Home() {
  return (
    <div className="flex flex-col items-center">
      <div className="bg-purple-50 w-full flex flex-col items-center">
        <Header />
        <section className="text-gray-700 py-6 min-h-[90vh] max-w-6xl min-[800px]:mt-12 min-[800px]:pt-12 min-[800px]:pb-12 min-[800px]:pl-0 min-[800px]:pr-0 w-full mx-auto lg:px-0 px-4">
          <div className="w-full">
            <div className="text-center max-w-3xl mx-auto">
              <h1 className="text-5xl font-bold">
                Develop.<br></br> Test. <br></br> Deploy.<br></br> Iterate.
                <br></br> All In The Browser.
              </h1>
              <p className="text-5xl py-6">
                Discover the best{" "}
                <span className="font-bold">Arduino programming </span>and{" "}
                <span className="font-bold">data capture environment </span>
                available.
              </p>
              <div className="w-auto mx-auto text-sm font-semibold">
                <button
                  onClick={() => {
                    window.location.href = "/signup";
                  }}
                  className="transition-transform transform hover:scale-105 duration-300 bg-purple-400 items-center text-white cursor-pointer py-3 px-6 flex rounded-xl block mx-auto"
                >
                  Get Started
                </button>
              </div>
            </div>
            {/* <div className="pt-5 max-w-3xl mt-8 mx-auto border-2 border-gray-200 border-solid rounded-2xl overflow-visible">
              <div className="border-t-2 py-8 px-4 border-gray-200 border-solid">
                <div className="bg-purple-200 py-2 px-4 mb-4 rounded-xl">
                  Upload your code to the Arduino board with a single click
                </div>
                <div className="text-white bg-purple-600 py-2 px-4 mb-4 rounded-xl">
                  <p>
                    Streamline your development process by using the intuitive,
                    feature-rich IDE designed for embedded systems.
                  </p>
                </div>
                <div className="bg-purple-200 py-2 px-4 mb-4 rounded-xl">
                  Code for hundreds of Arduino-compatible boards.
                </div>
                <div className="text-white bg-purple-600 py-2 px-4 rounded-xl">
                  <p>1. Compatible with a wide range of microcontrollers.</p>
                  <p>2. Integrated libraries for rapid prototyping.</p>
                  <p>
                    3. Support for serial communication and real-time debugging.
                  </p>
                </div>
              </div>
            </div> */}
            <div>
              <img
                src={require("../images/duino-screenshot.png")}
                alt="duino-screenshot"
                className="w-full rounded-2xl mt-8 w-3/4 block mx-auto"
              />
            </div>
          </div>
        </section>
      </div>
      <div
        id="features"
        className="flex flex-col lg:flex-row text-gray-700 justify-between bg-purple-50 w-10/12 rounded-2xl p-6 mb-10 transition-transform transform hover:scale-105 hover:bg-purple-200 duration-300 mt-10"
      >
        <img
          src="https://images.unsplash.com/photo-1603732551658-5fabbafa84eb?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="Feature 1"
          className="lg:w-[500px] rounded-2xl w-80"
        />
        <div className="flex justify-center flex-col ">
          <p className="text-5xl font-semibold">No Software Required.</p>
          <p className="lg:w-[500px] mt-4">
            With the in browser Arduino IDE, writing and uploading your code to
            the board is a breeze.
          </p>
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row text-gray-700 justify-between bg-purple-50 w-10/12 rounded-2xl p-6 mb-10 transition-transform transform hover:scale-105 hover:bg-purple-200 duration-300">
        <div className="flex justify-center flex-col ">
          <p className="text-5xl font-semibold">Extensive Library Support</p>
          <p className="lg:w-[500px] mt-4">
            Use built-in libraries to access sensors, motors, and displays for
            rapid prototyping.
          </p>
        </div>
        <img
          src="https://images.unsplash.com/photo-1651609236271-b7d6f8f52ef6?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="Feature 2"
          className="lg:w-[500px] w-80 rounded-2xl"
        />
      </div>
      <div className="flex flex-col lg:flex-row text-gray-700 justify-between bg-purple-50 w-10/12 rounded-2xl p-6 mb-10 transition-transform transform hover:scale-105 hover:bg-purple-200 duration-300">
        <img
          src={require("../images/duino-ss2.png")}
          alt="Feature 3"
          className="lg:w-[500px] w-80 rounded-2xl"
        />
        <div className="flex justify-center flex-col ">
          <p className="text-5xl font-semibold">Real Time Data Capture</p>
          <p className="lg:w-[500px] mt-4 ">
            Serial Read data in real time, export data to your favorite software
            for analysis. Create real time data plots for export.
          </p>
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row text-gray-700 justify-between bg-purple-50 w-10/12 rounded-2xl p-6 mb-10 transition-transform transform hover:scale-105 hover:bg-purple-200 duration-300">
        <div className="flex justify-center flex-col ">
          <p className="text-5xl font-semibold">Data Visualization Enhanced</p>
          <p className="lg:w-[500px] mt-4">
            With real time data visualization, pause, resume and understand your
            acquired data.
          </p>
          {/* <button className="text-white items-center cursor-pointer text-sm font-semibold py-2 px-3 text-center flex w-40 rounded-xl bg-purple-400 mt-4 transition-transform transform hover:scale-105 hover:bg-purple-600 duration-300">
            Download IDE
          </button> */}
        </div>
        <img
          src={require("../images/duino-ss3.png")}
          alt="Feature 4"
          className="lg:w-[500px] w-80 rounded-2xl"
        />
      </div>
      <Plan />
      <div className="text-gray-700 items-center bg-gradient-to-r from-purple-200 to-purple-50 flex flex-col max-w-6xl rounded-3xl gap-4 p-8 font-bold min-[800px]:flex-row min-[800px]:justify-around lg:w-full mx-10 my-16">
        <h3 className="text-3xl text-center min-[800px]:text-left">
          Start Developing with Arduino IDE
        </h3>
        <button
          onClick={() => {
            window.location.href = "/signup";
          }}
          className="text-white bg-purple-600 items-center cursor-pointer text-lg py-4 px-8 text-center flex justify-center rounded-xl transition-transform transform hover:scale-105 duration-300"
        >
          Get Started
        </button>
      </div>
      <Contact />
      <Footer />
    </div>
  );
}

export default Home;
