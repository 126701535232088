import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Contact() {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiUrl = process.env.REACT_APP_API_URL; // Fetch API URL from .env
      const res = await axios.post(`${apiUrl}/send-message/`, {
        email,
        subject,
        message,
        recipient: "timotiusalbert46@gmail.com",
      });
      toast.success("Message sent successfully!");
    } catch (error) {
      toast.error("Failed to send message");
    }
  };

  return (
    <div
      id="contact"
      className="text-gray-700 py-8 px-4 max-w-[50rem] min-[976px]:pt-16 min-[976px]:pb-16 mx-auto"
    >
      <h2 className="text-4xl font-extrabold text-center mb-4">Contact Us</h2>
      <p className="text-xl font-light text-center mb-8 min-[976px]:mb-16">
        Got a technical issue? Want to send feedback about a feature? Get in
        touch with the founder.
      </p>
      <form className="space-y-4" onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="text-sm font-medium mb-2 block">Your email</label>
          <input
            className="bg-white cursor-text px-4 w-full h-12 border-2 border-gray-700/20 rounded-lg"
            placeholder="Name@fastcontrol.io"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="text-sm font-medium mb-2 block">Subject</label>
          <input
            className="bg-white cursor-text px-4 w-full h-12 border-2 border-gray-700/20 rounded-lg"
            placeholder="Let us know how we can help you"
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>
        <div className="mb-4 text-sm" id="div-1">
          <label className="font-medium mb-2 block">Your message</label>
          <textarea
            className="bg-white cursor-text resize-y w-full h-48 min-h-[3rem] border-2 border-gray-700/20 rounded-lg p-3"
            placeholder="Write your message here..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="bg-purple-400 text-white text-sm font-medium py-3 px-5 flex justify-center rounded-xl w-full sm:w-auto transition-transform transform hover:scale-105 hover:bg-purple-600 duration-300"
        >
          Send Message
        </button>
      </form>
      <ToastContainer />
    </div>
  );
}
