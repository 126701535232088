import React from "react";

export default function Footer() {
  return (
    <div className="w-full flex flex-col items-center">
      <footer className="text-gray-700 text-sm grid gap-y-10 gap-x-4 w-9/12 p-10 grid-cols-1 md:grid-cols-2">
        <div className="space-y-2">
          <span className="text-2xl font-bold">FastControl</span>
          <p>A product by Warp.</p>
          <p>Copyright © 2024 - All rights reserved</p>
        </div>
        <div className="space-y-2 flex flex-col sm:items-end">
          <a href="/#features" className="hover:underline">
            {" "}
            Features
          </a>
          <a href="/#pricing" className="hover:underline">
            Pricing
          </a>
          <a href="/#contact" className="hover:underline">
            Contact
          </a>
        </div>
        {/* <div className="space-y-2 flex flex-col items-center">
          <a href="#" className="hover:underline">
            Terms of use
          </a>
          <a
            href="https://aiexcelbot.com/privacy-policy"
            className="hover:underline"
          >
            Privacy policy
          </a>
        </div> */}
      </footer>
    </div>
  );
}
